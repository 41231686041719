import decode from 'jwt-decode'

import { cookies } from './cookies'

export const saveToken = (token) => {
  cookies.set('token', token, { path: '/', maxAge: 60 * 60 * 24 * 60 })
  return Promise.resolve()
}

export const getToken = () => {
  return cookies.get('token')
}

export const getUserData = (token) => {
  if (token == null) return null

  return decode(token)
}

export const clearToken = () => {
  cookies.remove('token', { path: '/' })
}
