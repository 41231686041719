import { gql } from 'apollo-boost'

export const BREEDER_DATA = gql`
  fragment BreederData on Breeder {
    id
    email
    password
    firstName
    lastName
    companyName
    companyDescription
    companyLogo {
      thumb
      small
    }
    licenses {
      thumb
      description
      original
      filename
    }
    yearsOfExperience
    phone
    street
    city
    state
    zipcode
    country
    applicationStatus
    emailVerified
    stripeAccountId
    createdAt
    updatedAt
  }
`

export const BREEDER_RESERVATIONS_DATA = gql`
  fragment BreederReservationsData on Breeder {
    applicationsCount
    salesCount
  }
`

export const CUSTOMER_DATA = gql`
  fragment CustomerData on Customer {
    id
    email
    firstName
    lastName
    phone
    zipcode
    country
    street
    state
    city
    createdAt
    updatedAt
    pictureId
    picture {
      thumb
      small
    }
  }
`
export const AMBASSADOR_DATA = gql`
  fragment AmbassadorData on Ambassador {
    id
    email
    name
    lastName
    code
    verified
    amount
    isRegistered
    createdAt
    updatedAt
  }
`

export const CHAT_DATA = gql`
  fragment Chat on BreederCustomerChatInterface {
    id
    customerId
    breederId
    read
    sentBy
    updatedAt
    breeder {
      id
      firstName
      lastName
    }
    customer {
      id
      firstName
      lastName
    }
  }
  fragment ChatMessage on BreederCustomerChatMessage {
    message
  }
  fragment ChatImage on BreederCustomerChatImage {
    imageId
    image {
      small
    }
  }
`

export const MEETING_DATA = gql`
  fragment MeetingData on BreederCustomerMeeting {
    id
    date
    title
    online
    url
    location
    breederAccepted
    customerAccepted
    canceled
    customer {
      id
      firstName
      lastName
    }
    breeder {
      id
      firstName
      lastName
    }
  }
`

export const BASIC_BREED_DATA = gql`
  fragment BreedsBasicData on Breed {
    id
    name
    slug
    description
    dogsAvailable
  }
`
export const BREED_CATEGORIES = gql`
  fragment BreedCategories on Breed {
   kid_friendly
    small
    hypoallergenic
    guard
    emotional_support
    hunting
    active
    doodle
    service
    apartment
    dogsAvailable
    therapy
    trained
  }
`
