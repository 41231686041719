import { useState } from 'react'
import Script from 'next/script'
import { ApolloProvider } from '@apollo/client'
import { DiscountProvider } from '../context/discountContext'
import { useApollo } from '../utils/apolloClient'
import { useGoogleClickId } from '@/utils/googleClickId'

import 'swiper/css'
import 'react-image-crop/lib/ReactCrop.scss'
import '../ReactToastify.css'

export default function App({ Component, pageProps, err }) {
  const apolloClient = useApollo(pageProps.initialApolloState)
  const [isDiscountBannerClosed, setIsDiscountBannerClosed] = useState(false)

  useGoogleClickId()

  return (
    <>
      <ApolloProvider client={apolloClient}>
        <DiscountProvider>
          <Component
            {...pageProps}
            err={err}
            isDiscountBannerClosed={isDiscountBannerClosed}
            setIsDiscountBannerClosed={setIsDiscountBannerClosed}
          />

          <style jsx global>
            {`
              html,
              body {
                margin: 0px;
                padding: 0px;
              }

              * {
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                font-family: 'Circular Std Book', Helvetica, Arial, sans-serif;
              }

              @font-face {
                font-family: 'Circular Std Blk';
                src: url('/fonts/Circular Std Blk.ttf');
                font-display: swap;
              }
              @font-face {
                font-family: 'Circular Std bld';
                src: url('/fonts/Circular Std Bold.ttf');
                font-display: swap;
              }
              @font-face {
                font-family: 'Circular Std Book';
                src: url('/fonts/Circular Std Book.ttf');
                font-display: swap;
              }
              @font-face {
                font-family: 'Circular Std Medium';
                src: url('/fonts/Circular Std Medium.ttf');
                font-display: swap;
              }
            `}
          </style>
        </DiscountProvider>
      </ApolloProvider>

      {/* Live Chat */}
      <Script
        id="live-chat"
        strategy="worker"
        dangerouslySetInnerHTML={{
          __html: `
              window.__lc = window.__lc || {};
              window.__lc.license = 13257255;
              ;(function(n,t,c){function i(n){return e._h?e._h.apply(null,n):e._q.push(n)}var e={_q:[],_h:null,_v:"2.0",on:function(){i(["on",c.call(arguments)])},once:function(){i(["once",c.call(arguments)])},off:function(){i(["off",c.call(arguments)])},get:function(){if(!e._h)throw new Error("[LiveChatWidget] You can't use getters before load.");return i(["get",c.call(arguments)])},call:function(){i(["call",c.call(arguments)])},init:function(){var n=t.createElement("script");n.async=!0,n.type="text/javascript",n.src="https://cdn.livechatinc.com/tracking.js",t.head.appendChild(n)}};!n.__lc.asyncInit&&e.init(),n.LiveChatWidget=n.LiveChatWidget||e}(window,document,[].slice))
              `,
        }}
      />
    </>
  )
}
