import { useMemo } from 'react'
import { ApolloClient, InMemoryCache } from '@apollo/client'
import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory'
import { createHttpLink } from 'apollo-link-http'
import fetch from 'isomorphic-fetch'
import { getToken, getUserData } from './tokenStorage'
import ApolloLinkTimeout from 'apollo-link-timeout'

import introspectionQueryResultData from './fragmentTypes.json'

let apolloClient

const customFetch = (uri, options) => {
  let type = 'public' // Default type
  const token = getToken()
  if (token) {
    const userData = getUserData(token)

    if (userData.type === 'Customer') {
      type = 'customer'
    } else if (userData.type === 'Breeder') {
      type = 'breeder'
    } else if (userData.type === 'Admin') {
      type = 'admin'
    }
  } else {
    type = 'public'
  }
  options.headers.Authorization = `BEARER ${token}`
  return fetch(`${process.env.NEXT_PUBLIC_GQL_HOST}/graphql/${type}`, options)
}

function createLink () {
  return createHttpLink({
    fetch: customFetch
  })
}

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
})

function createApolloClient () {
  const timeoutLink = new ApolloLinkTimeout(70000) // 70 seconds timeout
  const timeoutHttpLink = timeoutLink.concat(createLink())

  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: timeoutHttpLink,
    cache: new InMemoryCache({ fragmentMatcher }),
    defaultOptions: {
      query: {
        fetchPolicy: 'no-cache'
      }
    }
  })
}

export function initializeApollo (initialState = null) {
  const _apolloClient = apolloClient ?? createApolloClient()

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // get hydrated here
  if (initialState) {
    _apolloClient.cache.restore(initialState)
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient

  return _apolloClient
}

export function useApollo (initialState) {
  const store = useMemo(() => initializeApollo(initialState), [initialState])
  return store
}
