import moment from 'moment'
import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { Discount, DiscountContextType } from './type'
import { GET_RANDOM_DISCOUNT } from '@/graphql/index'
import { initializeApollo } from '@/utils/apolloClient'

const defaultDiscountContextValue: DiscountContextType = {
  discount: {
    amount: 0,
    endDate: '',
    bannerText: '',
    terms: '',
    customerSpecific: false,
  },
  endDate: new Date(),
  now: new Date(),
  setDiscount: () => {},
  renderDiscountAmount: () => '',
}

export const DiscountContext = createContext<DiscountContextType>(
  defaultDiscountContextValue,
)
export const useDiscountContext = (): DiscountContextType =>
  useContext(DiscountContext)

const apolloClient = initializeApollo()

export function DiscountProvider({ children }) {
  // Discount
  const [discount, setDiscount] = useState<Discount | null>(null)
  const fetchRandomDiscount = async () => {
    const { data } = await apolloClient.query({ query: GET_RANDOM_DISCOUNT })
    setDiscount(data.randomDiscount)
  }

  const endDate: Date = moment.utc(discount?.endDate).toDate()
  const now: Date = moment.utc(new Date()).toDate()

  const renderDiscountAmount = useMemo(() => {
    return () => `$${discount?.amount / 100}`
  }, [discount])

  useEffect(() => {
    fetchRandomDiscount()
  }, [])
  return (
    <DiscountContext.Provider
      value={{
        renderDiscountAmount,
        discount,
        setDiscount,
        endDate,
        now,
      }}
    >
      {children}
    </DiscountContext.Provider>
  )
}
